import { firestore } from '@/plugins/firebase'

type subCategories = {
  uid: string,
  name: string
}
interface CategoryItem {
  uid: string;
  name: string;
  subCategories: subCategories[];
  translateKey?: string;
}

export const getCategories = async (): Promise<CategoryItem[]> => {
  const query = await firestore.collection('categories')
    .orderBy('name')
    .get()

  return query.docs
    .map((categorySnap) => {
      const { uid, name, translateKey, subCategories } = categorySnap.data()

      return { uid, name, translateKey, subCategories }
    })
}

export const getCategory = async (categoryId: string): Promise<CategoryItem> => {
  const categories = await getCategories()
  const category = categories.find(category => category.uid === categoryId)

  if (category) {
    return category
  }
  throw new Error("Category not found")
}
