<template>
  <div class="w-full space-y-4">
    <div>
      <label for="category">{{ $t('page.settings.category.form.category.label') }}</label>
      <multi-select
        v-model="selectedCategory"
        name="category"
        :options="options"
        mode="single"
        :placeholder="$t('page.settings.category.form.category.label')"
        searchable
      />
  
      <div>
        <a
          href="javascript:void(0)"
          @click.prevent="openInfo"
          class="text-sm text-blue-600 block"
        >
          {{ $t('page.settings.category.action.category.help_message') }}
        </a>
      </div>
    </div>

    <base-input
      :label="$t('page.settings.profile_identification.form.website.label')"
      name="website"
      type="url"
      v-model="website"
      autocomplete="off"
      :autofocus="true"
      left-icon="fas fa-globe"
      :help="$t('page.settings.profile_identification.form.website.help_message')"
    />

    <div>
      <label for="language">{{ $t('page.settings.profile-language.form.profile-language.label') }}</label>
      <multi-select
        v-model="selectedLanguage"
        name="language"
        :options="languages"
        mode="single"
        :placeholder="$t('page.settings.profile-language.form.profile-language.label')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, watch, computed } from 'vue'
import Swal from 'sweetalert2'
import BaseInput from '@/components/base/BaseInput.vue'
import MultiSelect from '@vueform/multiselect'
import { useCategory } from '@/composables/useCategory'
import { useI18n } from 'vue-i18n'
import { availableLanguages } from '@/plugins/i18n'

interface CategoryItem {
  uid: string;
  name: string;
}

export default defineComponent({
  components: {
    BaseInput,
    MultiSelect,
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  emits: [
    'update:form'
  ],
  setup (props, { emit }) {
    const { locale } = useI18n()

    const website = ref(props.form.website)
    const selectedCategory = ref<string>()
    const selectedLanguage = ref<string>()
    const categories = ref<CategoryItem[]>([])

    const options = computed(() => categories.value.map((category) => {
      return {
        value: category.uid,
        label: category.name,
      }
    }))

    const languages = availableLanguages
      .filter((language) => language.context.includes('content'))
      .map((language) => ({
        value: language.value,
        label: language.caption,
      }))


    const { getCategories } = useCategory()

    onBeforeMount(async () => {
      categories.value = await getCategories()

      if (props.form && props.form.category !== undefined) {
        selectedCategory.value = props.form.category.uid
      }

      selectedLanguage.value = props.form?.language || locale.value
    })

    watch(selectedCategory, (categoryId) => {
      emit('update:form', {
        category: categories.value.find((category) => category.uid === categoryId)
      })
    })

    watch(website, (website) => {
      emit('update:form', {
        website
      })
    })

    watch(selectedLanguage, (language) => {
      emit('update:form', {
        language
      })
    })

    const openInfo = () => {
      Swal.fire({
        title: 'Como escolher meu ramo de atuação?',
        confirmButtonText: 'Entendi',
        html: `<div  class="text-sm mb-12">
          <p class="my-6">
            Selecione o ramo de atuação que represente a sua atividade principal, por exemplo:
          </p>
          <p class="my-6">
            Você deseja utilizar <span class="font-bold font-sora text-teal-600">Marketing Digital</span> para promover um curso de <span class="font-bold font-sora text-teal-600">idiomas</span>, qual ramo de atividade você deve selecionar?
          </p>
          <div class="lg:grid lg:grid-cols-2 lg:gap-10 space-y-8 lg:space-y-0 text-left">
            <div>
              <b>Escolha correta:</b>
              <ul class="mt-3">
                <li><i class="fas fa-check-circle text-green-500"></i> Idiomas</li>
              </ul>
            </div>
            <div>
              <b>Escolha errada:</b>
              <ul class="mt-3">
                <li><i class="fas fa-times-circle text-red-500"></i> Marketing Digital</li>
              </ul>
            </div>
          </div>
        </div>`,
      })
    }

    return {
      website,
      options,
      selectedCategory,
      languages,
      selectedLanguage,
      openInfo,
    }
  }
})
</script>
