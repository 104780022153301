import { useI18n } from 'vue-i18n'
import {
  getCategories as getCategoriesService,
  getCategory as getCategoryService
} from '@/services/firestore/categories'

interface CategoryItem {
  uid: string;
  name: string;
  translateKey?: string;
}

export const useCategory = () => {
  const { t } = useI18n()

  const translateCategoryName = (category: any) => {
    if (category.translateKey) {
      return t(`constant.category.${category.translateKey}`)
    }
    return category.name
  }

  const getCategories = async () : Promise<CategoryItem[]> => {
    const availableCategories = await getCategoriesService()
    return availableCategories
      .map(category => ({
        uid: category.uid,
        name: translateCategoryName(category),
        subCategories: category.subCategories
      }))
      .sort((a, b) => a.name > b.name ? 1 : -1)
  }

  const getSubCategory = async (categoryId: string) : Promise<CategoryItem> => {
    const category = await getCategoryService(categoryId)
    return {
      uid: category.subCategories[0].uid,
      name: category.subCategories[0].name
    }
  }



  return {
    translateCategoryName,
    getCategories,
    getSubCategory
  }
}